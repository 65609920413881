const requestUrl = {
    auth: {
        // 用户
        login: "/api/auth/sign-in", // 登录 POST
        logout: "/api/auth/sign-out", // 登出 POST
        register: "/api/auth/sign-up", // 注册 POST
        logged: "/api/sso/auth/logged", //检查是否登录当前系统  若没有 http状态码为 403
        ssoLogin: "/api/sso/auth", // SSO 登录GET/POST
        ssoLogout: "/api/sso/auth/sign-out", // SSO 登出接口
    },
    bot: {
        // 用户
        botSkillIntentTree: "/api/bot/tree", // 获取机器人树，包含机器人知识库分类意图 GET
        createBot: "/api/bot", // 创建机器人 POST
        getBotInfo: "/api/bot", // 获取机器人详情 GET
        getBotList: "/api/bot/list", // 获取机器人列表 GET
        deleteBot: "/api/bot", // 删除机器人 DELETE
        updateBot: "/api/bot", // 更新机器人 PUT
        createRefBot: "/api/bot/template/create", //根据模板创建机器人 POST
        getBotadvancedSetting: "/api/bot/advanced-settings", // 获取机器人高级设置 GET
        updateUserBotOrder: "api/bot/updateUserBotOrder", // PUT 修改用户机器人顺序
        getBotProfilePhoto: "/api/bot/getBotProfilePhoto", // 获取机器人头像图标 GET
        saveBotProfilePhoto: "/api/bot/saveBotProfilePhoto", // '保存机器人头像图标' POST
        deleteBotProfilePhoto: "/api/bot/deleteBotProfilePhoto", // '删除用户自定义机器人头像图标' DELETE
        selectBotRelatedSkill: "/api/bot/selectBotRelatedSkill", // 查询机器人是否引用过知识库
        selectLimitMsg: "/portal-api/company/selectLimitMsg", // 查询机器人/意图创建限制信息 GET
        getUserCompanyById: "/portal-api/company/getUserCompanyById", // 获取用户公司信息 GET
        getKeywordRecommend: "/api/recommend/config/keyword", // 获取推荐关键词 GET
        cancelRedPot: "/api/recommend/config/keyword/view", // 取消小红点 PUT
        acceptConfigKeyword: "/api/recommend/config/keyword/accept", // 使用推荐关键词 PUT
        ignoreConfigKeyword: "/api/recommend/config/keyword/ignore", // 忽略推荐关键词 PUT
        intent4keyword: "/api/recommend/intent4keyword", // 根据关键词获取推荐意图 GET
        batchAcceptConfigKeyword: "/api/recommend/config/keyword/batch/accept", // 批量使用推荐关键词 PUT
        selectDefaultAgentLogo: "/api/bot/selectDefaultAgentLogo", // 获取默认应用内头像/默认网站接入形像, type=1应用内, type=2网站接入 GET
        savePlatformLogoSet:"/api/bot/savePlatformLogoSet", // 保存/修改形象设置 post
        selectPlatformLogoSetByPlatformId:"/api/bot/selectPlatformLogoSetByPlatformId", // 根据网站接入渠道ID查询一个形象设置信息 GET
        botKnowledge: "/api/tools/export/bot-knowledge", // 机器人意图导出 POST
        getExportProgress: "/api/tools/getExportProgress", // 获取导出进度（GET）
        
    },
    skill: {
        // 知识库
        getSkillList: "/api/skill", // 机器人知识库列表 GET
        createSkill: "/api/skill", // 创建知识库 POST
        updateSkill: "/api/skill", // 创建知识库 PUT
        templateSkillList: "/api/skill/templates", // 官方知识库列表 GET
        templateSkillListAll: "/api/skill/templates/all", // 官方/企业知识库 GET
        skillTree: "/api/skill/tree", // 知识库树 GET
        skillTemplateConfig: "/api/skill/template/config", // 配置知识库模板 POST
        skillTemplateUser: "/api/skill/template/user", // 查询用户的知识库模板，只包含用户有权限配置的 GET
        getIntentStatistical: "/api/skill/getIntentStatistical", // 获取意图更新统计 POST
    },
    intentQuestion: {
        // 意图问法
        addIntentQ: "/api/intent-question", // 添加单个问法 POST
        deleteIntentQ: "/api/intent-question", // 删除问法 DELETE
        batchuploadIntentQ: "/api/intent-question/batch", // 批量导入问题 POST
        batchuploadIntentQProcess: "/api/intent-question/batch/progress", // 请求文件上传进度
        getIntentQList: "/api/intent-question/list", // 问法列表 GET
        downloadIntentQTemp: "/api/intent-question/template", // 下载问题导入模板
        changeQuestionIntent: "/api/intent-question/change-question-intent", // 更改问题所属意图 POST
        ignoreWorkOrderDescription:
            "/api/combedKBase/ignoreWorkOrderDescription", // 对话知识库-问法识别-工单问题推荐忽略工单问题描述(GET)
        searchIntentStatus: "/api/intent-question", // 获取问法状态 GET
        imageChange:"/api/intent/image/change",//测试窗标注图片 POST
    },
    intentKeywordRule: {
        // 意图规则关键词
        addIntentKR: "/api/intent-keyword-rule", // 新增意图规则 POST
        getIntentKRList: "/api/intent-keyword-rule/list", // 获取意图规则列表 GET
        updateIntentKR: "/api/intent-keyword-rule", // 更新意图规则列表 PUT
        deleteIntentKR: "/api/intent-keyword-rule", // 删除意图规则 DELETE
    },
    intent: {
        // 意图相关
        batchOperate: "/api/intent/batch/operate", // 批量操作意图 POST
        copyIntent: "/api//intent/copy", // 复制意图 POST
        syncIntent: "/api/intent/sync/process/", // 同步流程 POST
        syncProcessTree: "/api/intent/sync-process/tree/", // 需要同步流程的意图树 GET
        batchSyncProcess: "/api/intent/batch/sync/process", // 批量同步意图流程 POST
        // 意图分类
        getIntentTypeList: "/api/intent/types", // 获取意图分类列表 GET
        addIntentType: "/api/intent/types", // 新增or更新意图分类 POST
        updateIntentType: "/api/intent/type", // 更新意图分类 PUT
        deleteIntentType: "/api/intent/type", // 删除意图分类 DELETE
        // 单个意图操作
        getIntentList: "/api/intent/list", // 获取意图列表 GET
        getIntentTree: "/api/intent/tree", // 获取意图树状结构 GET
        addIntent: "/api/intent", // 新增意图 POST
        updateIntent: "/api/intent", // 更新意图 PUT
        deleteIntent: "/api/intent", // 删除意图 DELETE
        deleteIntentWithReason: "/api/intent/delete/", // 删除意图 DELETE
        showEntranceCopy: "/api/intent/show/entrance/copy/", // 是否显示意图拷贝的入口 GET
        intentSort: "/api/intent/sort", // 意图排序 PUT
        // 归档意图列表
        getClosedIntentList: "/api/intent/list/close-intent", // 分类查询关闭的意图列表
        checkProcessRelation: "/api/intent/check/process/relation", // 校验意图流程关联关系 POST
        getSimilarIntent: "/api/intent/similar-intent", // 根据意图名称及样例文法查询相似意图 GET
        searchAllSkillIntent: "/api/intent/searchAllSkillIntent", //搜索机器人所有知识库下的意图列表
        intentImport: "/api/intent/intentImport", //意图批量导入
        getImportProgress: "/api/intent/getImportProgress", // 获取意图批量导入进度
        cancelImport: "/api/bus/cancelImport", //取消意图导入
        getNoprocess: "/api/intent/noprocess/", // GET 获取当前机器人无流程意图列表 {botId}
        getImgageList: "/api/intent/image/list/", // GET 查询意图图片 
        addImage: "/api/intent/image/add", // 添加意图图片 POST
        deleteImage:"/api/intent/image/delete", // 删除意图图片 DELETE
        getRelatedIntent: "/api/intent/related/page", // 查询推荐的意图  GET
        relatedIntentOpen: "/api/intent/related/auto-open", // 获取自动弹窗 GET
        setRelatedIntentOpen: "/api/intent/related/auto-open", // 设置自动弹窗 POST
        ignoreRelated:"/api/intent/related/ignore", // 忽略推荐的意图 POST
    },
    intentNotice: {
        // 意图通知
        getIntentNoticeList: "/api/intent/notice/page", // 分页查询意图通知 GET
        getIntentNoticeListByIntentId: "/api/intent/notice/page-intent-id", // 根据意图ID查询对应的通知 GET
        getIntentNoticeReasons: "/api/intent/notice/reasons", // 查询操作对应的原因 GET
        isShowNoticeEntrance: "/api/intent/notice/show/entrance/", // 是否显示通知的入口 GET
        noticeRead: "/api/intent/notice/read", // 更新通知的状态 PUT
    },
    intentAnswer: {
        // 意图答案
        addIntentAnswer: "/api/intent-answer", // 新增答案 POST
        updateIntentAnswer: "/api/intent-answer", // 更新答案 PUT
        deleteIntentAnswer: "/api/intent-answer", // 删除答案 DELETE
    },
    entity: {
        // 实体
        getEntityList: "/api/v1/entity", // 获取实体列表 GET
        addEntity: "/api/v1/entity", // 添加实体 POST
        updateEntity: "/api/v1/entity", // 更新实体 PUT
        deleteEntity: "/api/v1/entity", // 删除实体 DELETE
        getEntitySkills: "/api/v1/entity/skills", // 获取技能列表及所属的实体数量  GET
        getAllEntityBySkill: "/api/v1/entity/all", //  获取指定知识库下所有实体 GET
        getEntityTree: "/api/v1/entity/tree", //获取实体结构树

        getSingEntityInfo: "/api//v1/entity/", // 查询单个实体下的标准词、近义词（GET）
        entityContentChange: "/api/v1/entity/entityContentChange", // 实体标准词、近义词变更（PUT）changeType-操作类型，有效值: ENTITY_NAME_UPDATE(实体名称、描述修改)、ADD(标准词、近义词、正则新增)、UPDATE(标准词、近义词、正则修改)、DELETE(标准词、近义词删除)
        selectDataInterconnection: "/api/v1/entity/selectDataInterconnection", // 查询实体数据互联任务执行状态（GET），高频率调用
        selectDataInterconnectionOperate: "/api/v1/entity/selectDataInterconnectionOperate", // 查询实体数据互联配置（GET）
        dataInterconnectionOperate: "/api/v1/entity/dataInterconnectionOperate", // 添加/修改数据互联（POST）
        entityWordImport: "/api/v1/entity/entityWordImport", // 实体词批量导入(POST)
        entityExport: "/api//v1/entity/export", // 实体词导出(POST)
        selectExportStatus: "/api/v1/entity/selectExportStatus", // 查询实体词导出状态(GET)，高频率调用
    },
    dialog: {
        test: "/api/v1/dialog/test", // 测试窗问题GET
        test_v2: "/api/v2/dialog/test", // 测试窗问题GET
        test_v3: "/api/v3/dialog/test", // 测试窗问题POST
    },
    trainList: {
        intentTree: "/api/intent/tree", // 意图树 GET
        search: "/api/intent/tag/search", // 查询待标注问题 GET
        trainExport: "/api/intent/tag/trainingDetailsExport",
        skill: "/api/skill", // 机器人知识库列表 GET
        intentList: "/api/intent/list", // 关键字分类查询意图GET
        intentTypes: "/api/intent/types", // 查询分类 GET
        mark: "/api/intent/tag/mark", // 标注意图POST
        markBatch: "/api/intent/tag/mark-batch", // 批量标注意图POST
        trainList: "/api/record/train/search", // 训练记录 GET
        trainDetails: "/api/record/train/get", // 训练记录详情GET
        trainTag: "/api/intent/tag/train", // 训练机器人 POST
        removeTag: "/api/record/train/remove", // 移除标注记录 POST
        trainStatus: "/api/record/train/get-latest/", // 机器人训练状态 GET
        noteCount: "/api/intent/tag/count/", // 获取标注数量 GET
        skillIntent: "/api/intent/list/skill-intent", // 搜索意图 GET
        ignoreTag: "/api/intent/tag/ignore", // 忽略标注问题 PUT
        batchIgnoreTag: "/api/intent/tag/batch/ignore", // 批量忽略标注问题 POST
        getListByIntentIdentifyId:
            "/api/intent/taguser/getListByIntentIdentifyId", // GET 待标注问题涉及的会话
        getAnnotator: "/api/intent/tag/get-annotator", // GET 获取操作人列表
        getOverview: "/api/intent/tag/getOverview", // 获取标注状态总览 POST
        summaryTrainingRecords: "/api/record/train/summaryTrainingRecords", // 训练记录数据总览 POST
        recommendedIntent: "/api/intent/tag/recommendedIntent", // 获取推荐标注意图 GET
        getTagMsgNum: "/api/intent/tag/getTagMsgNum", // 获取待标注/已忽略问题数量 GET
        intentMove: "/api/intent/tag/intentMove", // 待标注意图移动（POST）
    },
    feedback: {
        answerFeedbackList: "/api/answer/feedback/page", // 分页查询答案反馈统计 GET
        answerFeedbackDetail: "/api/answer/feedback/detail", // 获取答案反馈详细信息 POST
        dialogProcess: "/api/v1/dialog/process", // 获取指定意图的流程 GET
        messageSessionHistory: "/api/message/session/history/", // 获取会话聊天记录 /message/session/history/{apiKey}/{sourceId} GET
    },
    chatFlowComponent: {
        // 对话流程组件
        getComponentsTypes: "/api/v1/dialog/design/component", // 获取流程组件类型列表 GET
        getConditionList: "/api/v1/dialog/design/component/condition", // 获取条件列表 GET
        getContitionScopeList:
            "/api/v1/dialog/design/component/condition/scope", // 获取条件作用于列表 GET
        getLastFeedback: "/api/bot/lastFeedback", // 获取机器人最后设置反馈 GET
    },
    chatFlowNode: {
        // 对话流程组件
        getIntentChatFlow: "/api/v1/dialog/process", // 获取指定意图流程 GET
        getStertChatFlow: "/api/v1/dialog/process/start_node", // 获取开始节点流程 GET
        getEndChatFlow: "/api/v1/dialog/process/end_node", // 获取结束节点流程 GET
        addNode: "/api/v1/dialog/process/node", // 添加节点 POST
        deleteNode: "/api/v1/dialog/process/node", // 删除节点 DELETE
        updateNode: "/api/v1/dialog/process/node/action", // 更新节点 PUT
        updateNodeName: "/api/v1/dialog/process/node/name", // 更新节点名称 PUT
        ipdateStartNodeNew: "/api/bot/start_node", // 更新保存开始节点
        ipdateEndNodeNew: "/api/bot/end_node", // 更新保存开始节点
        moveNode: "/api/v1/dialog/process/node/move", // 移动节点
    },
    eWechatBind: {
        //企业微信接入组件
        getAuthUrl: "/api/bind/ewechat/get/auth-url", //获取授权URL
        getAuthDomain: "/api/bind/ewechat/get/domain", //获取授权域
        getBindList: "/api/bind/ewechat/list", //绑定应用列表
        postUnbind: "/api/bind/ewechat/unbind", //解绑应用
        //'/api/bind/ewechat/detail/{bindId}'/api//查看绑定详细信息
    },
    wechatBind: {
        //企业微信接入组件
        getAuthUrl: "/api/bind/wechat/get/auth-url", //获取授权URL GET
        getBindList: "/api/bind/wechat/list", //绑定应用列表GET
        unbind: "/api/bind/wechat/unbind", //解绑应用POST
        detail: "/api/bind/wechat/detail/{bindId}", //查看绑定详细信息 GET
        changeAllowPushMsg: "/api/bind/wechat/change/status/", //更改应用推送状态 POST
        newWechatBind:"/api/bind/wechat/get/saveWechatBot", //新绑定接口
    },
    dingdingBind: {
        // 钉钉接入
        getDingtalkAuthUrl: "/api/bind/dingtalk/auth-url", // 获取授权URL GET
        getBindList: "/api/bind/dingtalk/list", // 绑定应用列表 GET
        unBind: "/api/bind/dingtalk/unbind", // 解绑应用 POST
        getOpenDomain: "/api/bind/dingtalk/platform-url", // 平台地址
        dingtalkWhitelist: "/api/bind/dingtalk/whitelist", // 获取ip白名单 GET
        getYunzhijiaAuthUrl:"/api/bind/yunzhijia/auth-url", //获取授权URL
    },
    dingdingSingBind: {
        // 钉钉单聊接入
        getDingtalkSingAuthUrl: "/api/bind/dingtalk/bot/bind", // 获取授权URL GET
    },
    miniProgramBind: {
        // 小程序接入
        wxMiniCode: "/api/wx/mini/acode", // 获取小程序码
    },
    bindV2: {
        // 改版绑定
        unbindApp: "/api/channel/access/unbind", // 解绑应用
        addWebsiteApp: "/api/channel/access/web", // 添加网站应用 POST
        addBindBot: "/api/channel/access/bind/bot", // 企业微信、公众号、钉钉绑定机器人接口 POST
        channelAccessList: "/api/channel/access/list/", // GET 获取绑定列表 渠道类型0：企业微信 1：公众号 2：小程序（暂无） 3：钉钉 4：网站   21 客户端
        departmentTree: "/api/channel/access/department/tree", // GET 获取部门成员树
        clientBindBot: "/api/channel/access/clientBindBot", // 客户端绑定机器人（POST）
        unifySetGreeting: "/api/channel/access/unifySetGreeting", // 应用欢迎语统一设置（POST）
        selectUnifySetGreeting: "/api/channel/access/selectUnifySetGreeting", // 查询应用欢迎语统一设置（GET）
        bindZhiyuanOa: "/api/channel/access/bindZhiYuanOa",
        checkStartNode: '/api/intent/check/startNode/relation', // 渠道管理删除机器人，校验机器人下所有意图是否被统一欢迎语配置使用（POST）
        getBotIntentTree: '/api/intent/bots/tree', // 获取机器人层面的意图（POST）
        getTagsOptions: '/api/tenant/customize-fields/tags', // 获取自定义标签数据
        getCustomizeFields:'/api/tenant/customize-fields', // 获取自定义字段
        getPersonalJobLevelOptions: '/api/tenant/customize-fields/options', // 获取个人职级数据
        knowledgeScope:"/api/channel/access/knowledge/scope"

    },
    websiteBind: {
        // 网站接入
        getBindWebsiteList: "/api/bot/web/access/list", // 获取绑定网站列表GET
        addWebsiteBind: "/api/bot/web/access", //新增网站接入信息POST
        deleteWebsiteBind: "/api/bot/web/access", //删除网站接入信息DELETE
        corps4main: "/portal-api/department/corps4main", // 获取主体下的公司 GET
        updateWebInfo:"api/channel/access/web" // 更新网站应用信息 PUT

    },
    wechatKF: {
        // 微信客服接入
        addBindWechatKF: "/api/bind/wechat-kf", // 添加微信客服应用 POST
        bindBindWechatKF: "/api/bind/wechat-kf/bind", // 微信客服应用绑定 PUT
        getWechatKFList: "/api/bind/wechat-kf/list", // 获取微信客服绑定应用列表 GET
        unbindBindWechatKF: "/api/bind/wechat-kf/unbind", // 微信客服应用解除绑定 PUT
        getWechatCustomer: "/portal-api/wechat-customer-service", // 获取客服账号 // GET
        selectOrganization: "/portal-api/upAndDown/selectOrganizationTree", // GET 获取上下游树
    },
    dingdingGroup: {
        // 钉钉群接入
        dingtalkGroupBind: "/api/bind/dingtalk/group/bind", // 绑定钉钉群 POST
        dingtalkGroupTemplateBind: "/api/bind/dingtalk/group/template/bind", // 绑定钉钉群模板 POST
        getDingdinggroupByTemplate: "/api/bind/dingtalk/group/list/", // GET 根据群模板ID分页查询绑定的群
        sync2group: "/api/bind/dingtalk/group/sync2group", // POST 同步群模板机器人到绑定的群
        dingtalkGroupBatchChange:
            "/api/bind/dingtalk/group/batch/change/visible", // PUT {groupTemplateId} 批量设置是否全员回复开关
        dingtalkGroupChange: "/api/bind/dingtalk/group/change/visible", // PUT {bindId} 单个设置是否全员回复开关
    },
    messageController: {
        //聊天记录组件
        getChatbotMessageList: "/api/message/dialog/", //获取机器人聊天列表GET
        getUserDialog: "/api/message/history/", //获取聊天记录GET
        getUserInformation: "/api/message/userInfo/", //获取用户信息GET
    },
    superIntent: {
        //超级意图模板
        updateSuperIntentSwitch: "/api/template/intent/enable", //开启关闭超级意图模板功能PUT
        getIntentMatchTemplate: "/api/template/intent/get/", //获取意图对应的模板GET
        updateIntentMatchTemplate: "/api/template/intent/save", //保存意图对应的模板POST
        getSuperIntentList: "/api/template/list", //获取超级意图列表GET
    },
    statistics: {
        //统计分析
        getStatisticsData: "/api/statistics/data", //获取统计数据GET
        getChannelList: "/api/statistics/source", //获取接入渠道GET
        getStatisticsScopes: "/api/statistics/scopes", // 获取统计维度列表 GET
        configQuestionType: "/api/statistics/config/question-type/", // 设置问题类型咨询占比 POST
        getConfigQuestionType: "/api/statistics/config/question-type/", // 设置问题类型咨询占比 GET
        getIntentTreeByScope: "/api/statistics/tree-without-null/", // 获取对应统计范围的机器人树，包含机器人知识库分类意图，忽略没有意图的分类 GET
        getAreaConfigInfo: "/api/statistics/config/area/", // {scope} GET 获取地域配置信息
        areaConfigInfo: "/api/statistics/config/area/", // {scope} POST 配置地域信息
        exportStatic: "/api/statistics/export/", // 导出数据
        questionConsult: "/api/statistics/config/question-consult/", // 设置统计分类  POST
        questionType: "/api/statistics/config/question-consult/", // 获取设置统计分类  GET
        getDefaultData: "/api/statistics/config/depart-situation/", // 获取设置部门的默认数据 GET
        statisticsViewDisplayControl:"/api/statistics/statisticsViewDisplayControl", // 获取机器人是否含有答案反馈 POST
    },
    accountManage: {
        //成员管理
        getAccountList: "/api/account/list", //获取成员列表GET
        account: "/api/account", //成员管理POST
        deleteAccount: "/api/account", // 删除账号DELETE
        updateAccount: "/api/account", // 更新账号PUT
    },
    role: {
        //角色管理
        getRoleList: "/api/role", //获取角色列表GET
    },
    accountGroup: {
        //用户分组管理
        getAccountGroupList: "/api/user/group", //获取用户分组列表GET
        addAccountGroup: "/api/user/group", //新增用户组POST
        updateAccountGroup: "/api/user/group", //更新用户分组PUT
        deleteAccountGroup: "/api/user/group", //删除用户分组DELETE
    },
    relatedQuestion: {
        //关联问法推荐
        getRelatedQuestionList: "/api/rq/list", //获取用户分组列表GET
        addOneRelatedQuestion: "/api/rq/add", //添加一个关联问法POST
        addAllRelatedQuestion: "/api/rq/add-all", //添加全部关联问法POST
        ignoreOneRelatedQuestion: "/api/rq/ignore", //忽略一个关联问法POST
        ignoreAllRelatedQuestion: "/api/rq/ignore-all", //忽略全部关联问法POST
        queryQuestionRecommendedStatus:"/api/intent-question/queryQuestionRecommendedStatus/", // 查询问法推荐执行状态
    },
    webhook: {
        getWebhookDir: "/api/webhook/dir/list", //获取Webhook目录列表GET
        addDir: "/api/webhook/dir", //新增目录POST
        deleteDir: "/api/webhook/dir", //删除目录DELETE
        deleteWebhook: "/api/webhook", //删除Webhook DELETE
        webhookDetail: "/api/webhook/", //查询api详情 GET
        webhookAdd: "/api/webhook", //新增修改webhook POST
        webhookSend: "/api/webhook/send", //发送webhook请求 POST
        webhookCopy: "/api/webhook/copy", //拷贝webhook POST
        webhookRelease: "/api/webhook", //发布webhook PUT
        uploadFile: "/api/oss", //上传文件 POST
        webhookNameCheck: "/api/webhook/check/name", //webhook名称重复校验 GET
        webhookbaseList: "api/webhook/base-list", // webhook列表（组件配置使用）
    },
    form: {
        formTree: "/api/form/tree", // 获取表单树 GET
        formTree4bot: "/api/form/tree4bot/", // 获取表单树 GET
    },
    accountInfo: {
        updateAccountInfo: "/api/account/", //更新账号信息PUT
        getAccountInfo: "/api/account/list/", //获取账户信息 GET
    },
    autoTag: {
        addAutoTagRule: "/api/auto-tag/rule", // 添加自动化标签规则 POST
        updateAutoTagRule: "/api/auto-tag/rule/", // 修改自动化标签规则 PUT
        deleteAutoTagRule: "/api/auto-tag/rule/", // 删除自动化标签规则 DELETE
        getAutoTagRuleList: "/api/auto-tag/rule/page", // 自动化标签规则分页查询 GET
        customClueSources: "/scrm-api/custom-clue/sources", // 获取来源options GET
        getBotTreeWithoutNull: "/api/bot/tree-without-null", // 获取机器人意图树 GET
        getEntityTreeWithoutNull: "/api/v1/entity/all-without-null", // 获取实体树 GET
        autoTagSourceCondition: "/scrm-api/client-tag/auto/source-condition", // 获取自动化标签来源对应的条件 POST
    },
    combedKBase: {
        getWorkOrderTypeTree: "/api/combedKBase/getWorkOrderTypeTree", //获取所有问题分类(GET)
        getQuestion: "/api/combedKBase/getQuestion", //获取每个问题分类下的问题(GET)
        associatedIntent: "/api/combedKBase/associatedIntent", //关联工单问题和意图(POST)
        cancelAssociatedIntent: "/api/combedKBase/cancelAssociatedIntent", //取消关联工单问题和意图(POST)
        getAssociatedQuestion: "/api/combedKBase/getAssociatedQuestion", //获取已关联问题(GET)
        cancelAssociated: "/api/combedKBase/cancelAssociated", //取消工单问题和推荐意图的关联(GET)
        ignoreWorkOrderDescription:
            "/api/combedKBase/ignoreWorkOrderDescription", //取消工单问题和推荐意图的关联(GET)
        tree: "/api/intent/tree", //意图列表get
    },
    department: {
        getDeptUser: "/portal-api/department/corpListDynamicLazy", // 根据主体下部门数量是否大于200动态决定是否懒加载 GET
        searchDepartmentOrUser: "/portal-api/department/search", // 搜索部门或者成员 GET
        getUpDownDeptData: "/portal-api/upAndDown/selectOrganizationTree", // GET 获取上下游组织架构树
        getUpDownMemberData:
            "/portal-api/upAndDown/selectUpAndDownMemberByOrganizationId", // GET 查询组织架构内非禁用的成员，不包含子级节点
        selectOrganizationById: "/portal-api/upAndDown/selectOrganizationById", // 根据ID获取上下游组织架构(一级) GET
        corpListLazy: "/portal-api/department/corpListLazy", // 懒加载主体下部门 GET
    },
    recommend: {
        keyword: "/api/recommend/keyword", // 近义词推荐 GET
        blacklist: "/api/recommend/keyword/blacklist", // 忽略接口 PUT
    },
    documentExtract: {
        selectIntelligentExtractionTaskTypeList:"/api/documentExtract/selectIntelligentExtractionTaskTypeList", // 查询任务分类树 GET
        addIntelligentExtractionTaskType: "/api/documentExtract/addIntelligentExtractionTaskType", // 添加任务分类 POST
        selectTaskByMyself: "/api/documentExtract/selectTaskByMyself", // 查询我的任务 GET
        updateIntelligentExtractionTaskType: "/api/documentExtract/updateIntelligentExtractionTaskType", //修改任务分类名称 GET
        delIntelligentExtractionTaskType:"/api/documentExtract/delIntelligentExtractionTaskType", // 删除任务分类树 DELETE
        selectTaskByType:"/api/documentExtract/selectTaskByType", // 查询分类下任务 GET
        delTask:"/api/documentExtract/delTask", // 删除任务  POST
        moveLocation: "/api/documentExtract/moveLocation", // 上移/下移 PUT
        createTask:"/api/documentExtract/createTask", // 创建任务 POST
        getProgress: "/api/documentExtract/getProgress", // 获取任务构建进度/文档抽取进度 GET
        selectTaskGroup: "/api/documentExtract/selectTaskGroup", // 查询任务组下任务 GET
        stopTask:"/api/documentExtract/stopTask", // 暂停任务GET
        updateTask:"/api/documentExtract/updateTask", // 编辑任务 PUT
        createHtml: "/api/documentExtract/createHtml", // 生成html  GET
        getIntentTree:"/api/documentExtract/tree", // 机器人树状结构 GET
        getExtractResult: "/api/documentExtract/getExtractResult", // 获取文档抽取结果 GET
        extractingNow: "/api/documentExtract/extractingNow", // 立即抽取 GET
        addExtractingResult: "/api/documentExtract/addExtractingResult", // 添加抽取结果 POST
        intentRecommend:"/api/documentExtract/intentRecommend", // 智能助手推荐标注意图 GET
        getTextForHtmlTag:"/api/documentExtract/getTextForHtmlTag", // 获取给抽取到意图加了标签的原文 GET
        getProgressGroup:"/api/documentExtract/getProgressGroup", // 获取任务组构建进度/文档抽取进度 POST
        selectPdfDocumentPicByDocumentId:"/api/documentExtract/selectPdfDocumentPicByDocumentId", // 获取当前文档的图片及其上下文信息 GET,
        catalogueTree:'/api/catalogue/tree', //获取目录树 GET
        catalogueSave:"/api/catalogue/save",//保存目录 POST
        catalogueDelete:"/api/catalogue/delete", //删除目录 DELETE
        catalogueUpdate:"/api/catalogue/update",//更新目录 PUT
        catalogueMove:"/api/catalogue/move", //移动目录 POST
        mrcTaskSave:"/api/mrc-task/save", //批量保存任务 POST
        mrcTaskList:"/api/mrc-task/list",//查询任务 POST
        mrcTaskDelete:"/api/mrc-task/delete/", //删除任务
        mrcTaskBatchDelete:"/api/mrc-task/batch-delete",//批量删除任务 POST
        mrcTaskMove:"/api/mrc-task/move", //移动任务 POST
        mrcTaskExtract:"/api/mrc-task/extract/", // 文档立即抽取
        questionList:"/api/mrc-task/document-extracting/list",
        updateQuestion:"/api/mrc-task/document-extracting/update"
    },
    botHealthCheck: {
        checkBotSetting: "/api/botHealthCheck/checkBotSetting", // 检查机器人设置是否开启答案反馈、快捷选择、不识别推荐、关键词,欢迎语配置智能引导 GET
        selectNoUpToStandardIntent:"/api/botHealthCheck/selectNoUpToStandardIntent", // 查询未达标意图 GET
        closeNoUpToStandardIntentView:"/api/botHealthCheck/closeNoUpToStandardIntentView", // 关闭用户进入某个机器人自动弹出未达标意图视窗  GET
        checkUseWebHookAndSmartWorkOrder:"/api/botHealthCheck/checkUseWebHookAndSmartWorkOrder", // 查看流程中是否使用WebHook能力或智能工单能力 GET
        selectBotLastHealthCheck:"/api/botHealthCheck/selectBotLastHealthCheck", // 获取机器人最后一次健康检查状态详细 GET
        checkDocumentExtracting: "/api/botHealthCheck/checkDocumentExtracting", // 查询使用文档抽取能力 GET
        updateOfficialIntentAnswer: "/api/botHealthCheck/updateOfficialIntentAnswer", // 查询修改官方意图答案 GET
        checkBotUseRuleAndSuperIntent:"/api/botHealthCheck/checkBotUseRuleAndSuperIntent", // 查看机器人使用规则和超级意图占比 GET
        machineLearningTrain:"/api/botHealthCheck/machineLearningTrain", // 机器学习训练周期 GET
        machineLearning:'api/botHealthCheck/machineLearning', // 计算机器学习未标注不识别问题数 GET
        answerFull: "api/botHealthCheck/answerFull", // 计算答案完整度 GET
        notQualified: "/api/botHealthCheck/notQualified", // 查询未达标意图数 GET
        querySelfBuiltIntentNum:"/api/botHealthCheck/querySelfBuiltIntentNum", // 计算自建意图数得分
        similarityQuestions:"/api/botHealthCheck/similarityQuestions", //  查询平均相似问法数 GET
        stopCheck:"/api/botHealthCheck/stopCheck", // 暂停检测 GET
        selectIntentDetailInBot:"/api/botHealthCheck/selectIntentDetailInBot", // 查询意图详情  POST
        botHealthCheckResultExport:"/api/botHealthCheck/botHealthCheckResultExport", // 机器人健康度检测结果导出 GET
        getCompanyIsOld:"/api/botHealthCheck/getCompanyIsOld", // 判断企业是不是旧客 GET

    },
    company: {
        getUserCompanyModule:"/portal-api/company/getUserCompanyModule", // 获取用户的系统入口 GET
        
    },
    appStore: {
        getAppList: "/agent-api/dev-app/page", // GET 获取应用列表
        getPublishAppList: "/agent-api/dev-app/list", // GET 获取已开通应用列表
        getAppDetail: "/agent-api/dev-app/detail", // GET 获取应用详情
        publishApp: '/agent-api/dev-app/publish', // POST 开通
        getConfig: '/agent-api/dev-app-auth-config/', // GET
        getActionList: '/agent-api/action-list/list/', // GET
    },
    exportQ: {
        exportQues: '/api/intent/tag/export', // 导出机器学习问题
    },
    knowledgeBase:{
        getFolderAndFile:"/knowledge-api/knowledgeBase/selectTreeFolderAndFile",
        knowledgeBaseTreeLazy:"/knowledge-api/knowledgeBase/selectKnowledgeBaseStructureTree",
        tagTree:"/tag-api/tag/selectTree", //GET 查询子标签
        pluginList:"/tag-api/plugins/private-available-with-tools"
    },
    export: {
        exportChat: "/api/message/dialog/export/", // 会话记录导出(POST)
    }
};

export { requestUrl };
